.services {
  display: flex;
  height: 120vh;
  top: "-10%";
}

.awsome {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
}

.awsome > :nth-child(1) {
  color: var(--black);
  font-size: 2.5rem;
  font-weight: 600;
}
.awsome > :nth-child(2) {
  color: var(--orange);
  font-size: 2.5rem;
  font-weight: 600;
}
/* blur */
.s-blur2 {
  left: 14rem;
  top: 8rem;
}

.s-blur1 {
  top: 13rem;
  left: -18rem;
}
.cards {
  flex: 1;
  display: flex;
  position: relative;
}

.cards > * {
  position: absolute;
}

@media screen and (max-width: 480px) {
  .services {
    margin-top: 20rem;
    flex-direction: column;
    gap: 5rem;
    height: 66rem;
    padding: 0;
  }

  .cards {
    display: flex;
    flex-direction: column;
    gap: 17rem;
  }
  .cards > * {
    position: static;
  }
}
