.intro {
  display: flex;
  height: 60vh;
  margin-top: 6rem;
}

.i-left {
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
}
.i-right {
  flex: 1;
}
.i-name {
  display: flex;
  flex-direction: column;
}
.i-name > :nth-child(1) {
  color: var(#1f1b75);
  font-weight: bolder;
  font-size: 1.97rem;
  display: inline-block;
  animation: fadeIn 2s ease-in-out forwards;
}
.i-name > :nth-child(2) {
  color: #252463;
  font-weight: 500;
  font-size: 1rem;
  display: inline-block;
  animation: fadeIn 2s ease-in-out forwards;
}
.i-icons {
  margin-top: 4px;
  display: flex;
  justify-content: left;
}

.i-icons > * {
  transform: scale(0.5);
}
.i-icons > *:hover {
  cursor: pointer;
}

.i-right {
  flex: 1;
  position: relative;
}

.i-right > * {
  z-index: 1;
  position: absolute;
}
.i-right-image {
  left: 5%;
  width: 100%;
  transform: scaleY(2);

  border: 10px;
  transform: scaleZ(0.1);
  border-radius: 10px; /* Optional: adds rounded corners */
}

@media screen and (max-width: 480px) {
  .Intro {
    flex-direction: column;
    gap: 7rem;
    height: 64rem;
  }
  .i-right .blur {
    display: none;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0.4;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
