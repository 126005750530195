:root {
  --yellow: #f5c32c;
  --orange: #fca61f;
  --black: #242d49;
  --gray: #788097;
  --blueCard: #ddf8fe;
  --purple: rgb(238 210 255);
  --boxShadow: 0px 19px 60px rgb(0 0 0 / 8%);
  --orangeCard: rgba(252, 166, 31, 0.45);
  --smboxShadow: -79px 51px 60px rgba(0, 0, 0, 0.08);
}

.App {
  padding: 0.5rem 3.5rem;
  overflow: hidden;
  color: var(--black);
}
.button {
  border-radius: 34px;
  border: none;
  color: white;
  font-size: 16px;
  padding: 11px 26px 11px 26px;
  background: linear-gradient(rgb(29, 37, 122), rgb(255, 255, 255));
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
}

.button:hover {
  background: white;
  cursor: pointer;
  color: var(--orange);
  border: 1px solid var(--orange);
}
@media screen and (max-width: 480px) {
  .App {
    padding: 0.5rem 1rem;
  }
}
